import styles from './Header.module.css'
import Logo from '../../Image/logo.png'
import { Link } from 'react-router-dom'

function Header() {
    return (
        <header>
            <div className={styles.logo}>
                <img src={Logo} alt="logo" />
            </div>
            <nav className={styles.navegacao}>
                <Link to="/"><li>Inicio</li></Link>
                <Link to="/Info"><li>Serviços</li></Link>
                <Link to="/About"><li>Sobre nós</li></Link>
                <Link to="/Parcerias"><li>Parceiros</li></Link>
                <Link to="/Creditos"><li>Creditos</li></Link>
            </nav>
            <div className={styles.botao}>
            <Link to="/">
                    <button className={styles.button}> Entrar
                    </button>
                </Link>
            </div>
        </header>
    )
}

export default Header;